import React from 'react'
import Layout from '../components/App/Layout'
import SEO from '../components/App/SEO';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import setupData from "../../data/setup.json"
import { graphql, Link } from 'gatsby'

export const query = graphql`
    query ($title: String!) {
        setupJson(title: {eq: $title}) {
            protocols {
              incoming {
                incoming_conn_security
                incoming_host
                incoming_port
                incoming_protocol
              }
              outgoing {
                outgoing_conn_security
                outgoing_host
                outgoing_port
                outgoing_protocol
              }
            }
            title
        }
    }
`

const Setup = ({ data }) => {
    
    const content = data.setupJson
    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);
    const dataShuffle = shuffle(setupData);
    const currentPage = content.title

    // Helper function to render protocol tables with inline styles
    // Helper function to render protocol tables with inline styles
const renderProtocolTable = (item, key, type) => {
    // Determine the protocol based on type
    const protocol = type === 'imap' || type === 'pop' ? item.incoming_protocol : item.outgoing_protocol;

    // Determine the connection security based on the type
    const security = type === 'imap' || type === 'pop' 
        ? (item.incoming_conn_security === "SSL_TLS_REQUIRED" || item.incoming_conn_security === "SSL_TLS_OPTIONAL"
            ? "SSL/TLS"
            : item.incoming_conn_security === "STARTTLS_REQUIRED" || item.incoming_conn_security === "STARTTLS_OPTIONAL"
            ? "STARTTLS"
            : item.incoming_conn_security)
        : (item.outgoing_conn_security === "SSL_TLS_REQUIRED" || item.outgoing_conn_security === "SSL_TLS_OPTIONAL"
            ? "SSL/TLS"
            : item.outgoing_conn_security === "STARTTLS_REQUIRED" || item.outgoing_conn_security === "STARTTLS_OPTIONAL"
            ? "STARTTLS"
            : item.outgoing_conn_security);

    if (protocol && protocol.includes(type)) {
        return (
            <table key={key} style={{ width: '100%', marginBottom: '20px', borderCollapse: 'collapse', border: '1px solid #ddd', borderRadius: '5px', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}>
                <thead>
                    <tr style={{ backgroundColor: '#f5f5f5', color: '#333', borderBottom: '2px solid #ddd' }}>
                        <th colSpan="2" style={{ padding: '12px', textAlign: 'left', fontSize: '18px', fontWeight: 'bold' }}>
                            {content.title} - {protocol.toUpperCase() === "SMTP" ? `Outgoing (${protocol.toUpperCase()})` : `Incoming (${protocol.toUpperCase()})`}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ padding: '12px', fontWeight: 'bold' }}>Server:</td>
                        <td style={{ padding: '12px' }}>{item.incoming_host || item.outgoing_host}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#fafafa' }}>
                        <td style={{ padding: '12px', fontWeight: 'bold' }}>Port:</td>
                        <td style={{ padding: '12px' }}>{item.incoming_port || item.outgoing_port}</td>
                    </tr>
                    <tr>
                        <td style={{ padding: '12px', fontWeight: 'bold' }}>Security:</td>
                        <td style={{ padding: '12px' }}>{security}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#fafafa' }}>
                        <td style={{ padding: '12px', fontWeight: 'bold' }}>Username:</td>
                        <td style={{ padding: '12px' }}>Your email address</td>
                    </tr>
                    <tr>
                        <td style={{ padding: '12px', fontWeight: 'bold' }}>Password:</td>
                        <td style={{ padding: '12px' }}>Your {content.title} password</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    return null;
};


    return (
        <Layout>
            <SEO
                postTitle={content.title + " | BlueMail App"}
                postDescription={"Setup settings for " + content.title}
                postImage='/img/OG/og_image-default-setup.png'
                postURL={`setup/${content.title.toLowerCase()}` + "-email-account-settings"}
            />
            <Navbar />
            <div className="setup-main">
                <div className="container">
                    <h2 style={{ fontSize: '26px', marginBottom: '20px' }}>Set up your <span>{content.title}</span> | The Correct IMAP and SMTP Settings</h2>
                    <hr />
                    <h4>How to access your <span className='title-p'>{content.title}</span> Account from any Email app using the right configurations</h4>
                    <p>Instead of using <span className='title-p'>{content.title}</span>'s webmail interface, you can use mobile and desktop email apps like BlueMail, Outlook, Apple Mail, or Mozilla Thunderbird. Desktop email apps offer multiple features along with quicker access, and your emails are still available on your computer or mobile phone even when you're offline.</p>
                    <h4>Setup Your <span className='title-p'>{content.title}</span> Account with Your Email app Using IMAP:</h4>
                    <p>To access your <span className='title-p'>{content.title}</span> account from a desktop email app or a mobile email app, you will need to configure the IMAP or POP3 and SMTP settings as shown below:</p>

                    {/* Protocol Tables */}
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', marginBottom: '30px' }}>
                        <div>
                            {content.protocols.incoming.map((item, key) => renderProtocolTable(item, key, "imap"))}
                        </div>
                        <div>
                            {content.protocols.outgoing.map((item, key) => renderProtocolTable(item, key, "smtp"))}
                        </div>
                    </div>

                    {/* POP Protocol Below */}
                    <div style={{ gridColumn: 'span 2', marginTop: '40px' }}>
                        {content.protocols.incoming.map((item, key) => renderProtocolTable(item, key, "pop"))}
                    </div>
                </div>
            </div>
            <div className='container pb-120'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-xs-12 mt-3'>
                        <h5>To setup additional providers:</h5>
                    </div>
                    <div className='col-lg-9 col-md-6 col-xs-12 d-flex justify-content-center align-items-center providers-box mt-3'>
                        {dataShuffle.slice(0, 3).map((data, index) => {
                            return (
                                <div className='content' key={index}>
                                    {data.title !== currentPage && (
                                        <div className='col-lg-3 col-md-3 col-xs-12 text-center'>
                                            <Link className='other-providers' style={{ fontWeight: "700" }} to={`/setup/${data.title.toLowerCase().replace(".", "-")}` + "-email-account-settings"}>
                                                {data.title}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default Setup
